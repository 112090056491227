import React from 'react'

import type { SeoProps } from './SeoWrapper'
import SeoWrapper from './SeoWrapper'

import Container from 'components/Container'
import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'
import type { LogoHeaderProps } from 'layouts/LogoHeader'
import LogoHeader from 'layouts/LogoHeader'
import Footer from 'modules/Footer/Footer'

export type LayoutProps = {
    children: React.ReactNode
    seoProps: SeoProps
    containChildren?: boolean
    hasFooter?: boolean
    hasHeader?: boolean
    headerProps?: LogoHeaderProps
    bg?: BoxProps['color']
}

const BareLayout = ({
    children,
    containChildren = true,
    hasFooter = false,
    hasHeader = true,
    headerProps,
    bg = 'accent.50',
    seoProps,
}: LayoutProps) => (
    <SeoWrapper seoProps={seoProps}>
        <Box bg={bg} minHeight="100%" display="flex" flexDirection="column">
            {hasHeader && <LogoHeader {...headerProps} />}

            {containChildren ? (
                <Container
                    style={{ flexGrow: 1 }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                >
                    <>{children}</>
                </Container>
            ) : (
                <>{children}</>
            )}

            {hasFooter && <Footer />}
        </Box>
    </SeoWrapper>
)

export default BareLayout
