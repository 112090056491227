import Image from 'components/Image'
import { LegacyLink as Link } from 'components/Link'
import Box from 'components/Primitives/Box'
import finimizeLogoPrimaryFull from 'public/images/finimize-primary-logo-full.svg'
import { routeMap } from 'shared/routes'

const CheckoutNavBar = () => {
    return (
        <Box>
            <Box
                m={0}
                display={'flex'}
                flexDirection={'column'}
                alignItems="start"
                paddingBottom={1}
                paddingTop={2}
                paddingX={2}
            >
                <Link url={routeMap.home}>
                    <Image
                        data-cy="home-logo-button"
                        alt="Finimize Logo"
                        src={finimizeLogoPrimaryFull}
                        width="200px"
                    />
                </Link>
            </Box>
        </Box>
    )
}

export default CheckoutNavBar
