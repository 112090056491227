import { addDays, addMonths, format } from 'date-fns'

import type { Discount } from 'lib/pricing/types'

// convert from yearly to monthly
const YEARLY_TO_MONTH_ADJUST_FACTOR = 12

// TODO would be nice to do this on the server
// Returns an integer in the unit of currency (e.g. 5999)
// given the 'amount' in £/$ we get from the server (e.g. 69.99)
// and a percent discount OR a discount amount in cents
export function calculateStripeTotalAmount(
    amount: number,
    stripeDiscountPct: number | null,
    stripeDiscountAmount: number | null,
): number {
    const amountInCents = amount * 100
    let price = amountInCents
    if (stripeDiscountPct) {
        price = amountInCents * (1 - stripeDiscountPct / 100)
    } else if (stripeDiscountAmount) {
        price = amountInCents - stripeDiscountAmount
    }

    return Math.round(price)
}

// Return an integer, or 2 decimal places
//e.g. £22 £19.80 £9.99
type createPriceStringArgs = {
    amount: number
    currency: string
    currencySymbol: string
    stripeDiscountPct: number | null
    stripeDiscountAmount: number | null
    hideCurrencyInfo?: boolean
    // optionally convert the price into a monthly price
    convertToMonth?: boolean
}

function createPriceString({
    amount,
    currency,
    currencySymbol,
    stripeDiscountPct,
    stripeDiscountAmount,
    hideCurrencyInfo = false,
    convertToMonth = false,
}: createPriceStringArgs): string {
    // TODO: Refactor so that amount and stripeDiscountAmount use the same unit
    // here and above in createPriceStringArgs
    let price
    if (stripeDiscountPct) {
        price = amount * (1 - stripeDiscountPct / 100)
    } else if (stripeDiscountAmount) {
        price = amount - stripeDiscountAmount / 100
    } else {
        price = amount
    }

    price = convertToMonth ? price / YEARLY_TO_MONTH_ADJUST_FACTOR : price
    const roundedPrice = Number.isInteger(price) && price !== 0 ? price : formatDecimalStr(price, 2)

    const currencyClarification: Record<string, string> = { usd: 'US' }

    let currencyInfo = ''

    if (!hideCurrencyInfo) {
        currencyInfo = currencyClarification[currency.toLowerCase()] || ''
    }

    return `${currencyInfo}${currencySymbol}${roundedPrice}`
}

export const getEndDateAfterDays = (trialDays: number | null) => {
    if (!trialDays) return null

    return format(addDays(new Date(), trialDays), 'yyyy-MM-dd')
}

export const getEndDateAfterMonths = (couponDurationInMonths: number | null) => {
    if (!couponDurationInMonths) return null
    return format(addMonths(new Date(), couponDurationInMonths), 'yyyy-MM-dd')
}

export const formatDecimalStr = (n: number, digits: number) => {
    return n.toLocaleString(undefined, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    })
}

export const getSmallPrint = (
    amount: number,
    currency: string,
    currencySymbol: string,
    interval: string,
    couponDurationInMonths: number | null,
    trialDays: number | null,
    discountPct: number | null,
    discountAmount: number | null,
): string => {
    const discountedAmount = createPriceString({
        amount,
        currency,
        currencySymbol,
        stripeDiscountPct: discountPct,
        stripeDiscountAmount: discountAmount,
    })

    const trialEndDate = trialDays && format(addDays(new Date(), trialDays), 'yyyy-MM-dd')
    const isDiscounted = discountPct || discountAmount

    // vary the start of the smallprint, keep the end the same
    let startOfDescription = `This subscription renews automatically each ${interval}${
        !!isDiscounted ? ' at full price' : ''
    }. You must cancel at least 24 hours before the end of your current subscription period for the change to take effect. `
    const endOfDescription = "Cancel anytime on your profile by selecting 'Manage Subscriptions'."

    if (trialDays) {
        // just add an explanation to the start of the sentence
        startOfDescription = `Your free trial will start today and end on ${trialEndDate}. Unless you cancel during this time, you will be charged ${discountedAmount}. `
        return startOfDescription + endOfDescription
    }

    if (isDiscounted) {
        // if coupon is null we run the promotion forever...
        if (!couponDurationInMonths) {
            startOfDescription = `This subscription renews automatically each ${interval}. You must cancel at least 24 hours before the end of your current subscription period for the change to take effect. `
            return startOfDescription + endOfDescription
        }

        // if the monthly coupon duration is different to the interval, we write something more complex.
        const isCouponDurationSameAsInterval =
            (interval === 'year' && couponDurationInMonths === 12) ||
            (interval === 'month' && couponDurationInMonths === 1)

        if (isCouponDurationSameAsInterval) {
            startOfDescription = `This subscription renews automatically each ${interval} at full price. You must cancel at least 24 hours before the end of your current subscription period for the change to take effect. `
            return startOfDescription + endOfDescription
        }

        startOfDescription = `This subscription renews automatically each ${interval} at ${discountedAmount} for the next ${couponDurationInMonths} months, and full price thereafter. You must cancel at least 24 hours before the end of your current subscription period for the change to take effect. `
        return startOfDescription + endOfDescription
    }

    return startOfDescription + endOfDescription
}

export const getPlanCheckoutSmallPrint = (
    isAnnualPlan: boolean,
    defaultPromotionTrialDays: number | null,
    defaultCalculatedPrice: string,
    calculatedMonthlyPrice: string,
    discountPct: number | null,
    discountAmount: number | null,
    currency: string,
    currencySymbol: string,
    amount: number,
    couponDurationInMonths: number | null,
) => {
    const isDiscounted = discountPct || discountAmount

    const discountedAmount = createPriceString({
        amount,
        currency,
        currencySymbol,
        stripeDiscountPct: discountPct,
        stripeDiscountAmount: discountAmount,
    })

    if (isAnnualPlan) {
        return defaultPromotionTrialDays
            ? `${defaultCalculatedPrice}/year (${calculatedMonthlyPrice}/month) billed annually after ${defaultPromotionTrialDays} day trial. Cancel anytime.`
            : `${calculatedMonthlyPrice}/month billed annually. Cancel anytime.`
    } else {
        if (isDiscounted) {
            return couponDurationInMonths
                ? `${discountedAmount}/month for the first ${couponDurationInMonths} months. Cancel anytime.`
                : `${discountedAmount}/month. Cancel anytime.`
        } else {
            return `${calculatedMonthlyPrice}/month. Cancel anytime.`
        }
    }
}

export const getDiscountText = (discount: Discount | null, currencySymbol: string) => {
    if (discount?.discountPct) return `${discount.discountPct}% OFF`
    if (discount?.discountAmount) return `${currencySymbol}${discount.discountAmount / 100} OFF`
    return null
}

export default createPriceString
