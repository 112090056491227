import styled from 'styled-components'

import HighlightText from 'components/HighlightText'
import Separator from 'components/Separator'
import createPriceString, {
    getEndDateAfterDays,
    getEndDateAfterMonths,
} from 'lib/createPriceString'
import type { Price } from 'lib/pricing/types'
import type { ThemedComponent } from 'theme'
import { trispace } from 'theme'
import { isAnnual } from 'types/stripeConsts'

type Props = {
    price: Price
    encryptedEmail?: string
}

export const PRICING_DETAIL_TITLE = 'Finimize Premium Membership'

export const PlansCheckoutDetail = ({ price }: Props) => {
    const { promotionApplied, interval, currency, currencySymbol, amount, trialDays = null } = price
    const {
        couponDurationInMonths = null,
        discountPct = null,
        discountAmount = null,
    } = promotionApplied || {}

    const firstPaymentPrice = createPriceString({
        amount: amount,
        currency: currency,
        currencySymbol: currencySymbol,
        stripeDiscountPct: discountPct,
        stripeDiscountAmount: discountAmount,
        hideCurrencyInfo: true,
    })

    const trialPaymentPrice = createPriceString({
        amount: 0,
        currency: currency,
        currencySymbol: currencySymbol,
        stripeDiscountPct: null,
        stripeDiscountAmount: null,
        hideCurrencyInfo: true,
    })

    const noDiscountPrice = createPriceString({
        amount: amount,
        currency: currency,
        currencySymbol: currencySymbol,
        stripeDiscountPct: null,
        stripeDiscountAmount: null,
        hideCurrencyInfo: true,
    })

    const displayedDiscountedAmount = !!discountAmount
        ? createPriceString({
              amount: discountAmount ? discountAmount / 100 : 0,
              currency: currency,
              currencySymbol: currencySymbol,
              stripeDiscountPct: null,
              stripeDiscountAmount: null,
              hideCurrencyInfo: true,
          })
        : null

    const hasDiscount = !!discountPct || !!discountAmount
    const hasTrial = !!trialDays
    const isAnnualPlan = !!interval && isAnnual(interval.toLowerCase())
    const discount = hasDiscount && (discountPct ? `${discountPct}%` : displayedDiscountedAmount)

    const trialEndDate = getEndDateAfterDays(trialDays)
    const promotionEndDate = getEndDateAfterMonths(couponDurationInMonths)

    const totalDueToday = hasTrial ? trialPaymentPrice : firstPaymentPrice

    return (
        <Container>
            <>
                <Header>Order summary</Header>
                <Text>{PRICING_DETAIL_TITLE}</Text>

                <Separator />

                {hasTrial && <PromotionText>Free {trialDays} day trial</PromotionText>}
                {hasDiscount && (
                    <PriceLineContainer>
                        <PromotionText>
                            Discount applied: <span data-cy="discount-amount">{discount} off</span>
                        </PromotionText>
                        <PriceText $isDiscounted={hasDiscount}>{noDiscountPrice}</PriceText>
                    </PriceLineContainer>
                )}

                <PriceLineContainer>
                    <Text>Total due today</Text>
                    <PriceText data-cy="first-payment-amount">{totalDueToday}</PriceText>
                </PriceLineContainer>

                {!!couponDurationInMonths && isAnnualPlan && (
                    <Text>Introductory price until {promotionEndDate}</Text>
                )}
                {!!couponDurationInMonths && !isAnnualPlan && (
                    <Text>
                        First {couponDurationInMonths} months at {firstPaymentPrice} then{' '}
                        {noDiscountPrice} per month thereafter
                    </Text>
                )}
                {hasTrial && (
                    <PriceLineContainer>
                        <Text>Total due on {trialEndDate}</Text>
                        <PriceText>{firstPaymentPrice}</PriceText>
                    </PriceLineContainer>
                )}
                {!isAnnualPlan && <Text>You can cancel anytime</Text>}
                {isAnnualPlan && hasDiscount && (
                    <Text>Renews at {noDiscountPrice} annually thereafter</Text>
                )}
                {isAnnualPlan && !hasDiscount && <Text>Renews annually thereafter</Text>}
            </>
        </Container>
    )
}

const Container = styled.div`
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    background-color: ${({ theme }) => theme.palette.neutral[50]};
`

const Header = styled(HighlightText)`
    font-size: ${({ theme }) => theme.fontSize.XL};
    font-weight: ${({ theme }) => theme.fontWeights.extraBold};
    color: ${({ theme }) => theme.palette.neutral[999]};
    text-transform: uppercase;
    font-style: italic;
    margin-bottom: 1rem;
    width: fit-content;
`

const PriceLineContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

const Text = styled.p`
    font-family: ${trispace.style.fontFamily};
    font-size: ${({ theme }) => theme.fontSize.S};
    color: ${({ theme }) => theme.palette.neutral[999]};
    padding: 0.5rem 0;
`

const PriceText = styled(Text)<ThemedComponent<{ $isDiscounted?: boolean }>>`
    text-decoration: ${({ $isDiscounted }) => $isDiscounted && 'line-through'};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
`

const PromotionText = styled(Text)`
    color: ${({ theme }) => theme.palette.primary[500]};
`

export default PlansCheckoutDetail
