import React from 'react'

import type { StaticImageData } from 'next/image'

import Container from 'components/Container'
import Hide from 'components/Hide'
import Image from 'components/Image'
import { LegacyLink as Link } from 'components/Link'
import type { BoxProps } from 'components/Primitives/Box';
import Box from 'components/Primitives/Box'
import finimizeLogoFullBrand from 'public/images/finimize-primary-logo-full.svg'
import { routeMap } from 'shared/routes'

export type LogoHeaderProps = {
    hideOn?: boolean[]
    headerContainerStyle?: BoxProps
    logo?: StaticImageData
}

export const LogoHeader = ({
    hideOn = [false, false, false],
    headerContainerStyle,
}: LogoHeaderProps) => {
    return (
        <Hide on={hideOn}>
            <Box bg="white" style={{ flexGrow: 0, flexShrink: 0 }}>
                <Container
                    position="initial"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    py={1}
                    height="64px"
                    data-cy="logo-header"
                    {...headerContainerStyle}
                >
                    <Link url={routeMap.home} display="flex">
                        <Image src={finimizeLogoFullBrand} alt="Finimize logo" maxWidth="170px" />
                    </Link>
                </Container>
            </Box>
        </Hide>
    )
}

export default LogoHeader
