/**
 * Adds a thin line with a color, typically
 * used as an alternative to border to separate
 * content
 */
import React from 'react'

import styled, { css } from 'styled-components'

import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'
import type { ColorName } from 'theme'
import { getColor, zIndex } from 'theme'
import type { Omit } from 'types'

interface LocalProps {
    children?: React.ReactNode
    color: string
    isVertical?: boolean
}

export type SeparatorProps = LocalProps & Omit<BoxProps, 'color'>

const Separator = ({ children, color, isVertical, ...props }: SeparatorProps) => {
    const marginProps = isVertical ? { mx: '1rem' } : { my: '1rem' }

    return (
        <_Separator as="hr" {...marginProps} {...{ children, color, isVertical }} {...props}>
            {children}
        </_Separator>
    )
}

const separatorDefaultProps = {
    color: 'grey3',
    isVertical: false,
}

Separator.defaultProps = separatorDefaultProps
export default Separator

const _Separator = styled(Box)<SeparatorProps>`
    opacity: 1;
    border-color: ${props => getColor(props.color as ColorName)};
    border-top: 1px;
    border-style: solid;
    background-color: transparent;
    position: relative;
    z-index: ${zIndex.layer01};
    display: ${props => props.display || 'flex'};
    align-items: center;
    justify-content: center;

    & {
        ${props =>
            props.isVertical &&
            css`
                height: auto;
                border-width: 0 0 0 1px;
            `}
    }
`
